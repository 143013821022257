import { useTranslation } from "components/translate";
import React from "react";
import FaqList from "modules/non-protected-page/faq-list";

export const useFaqList = () => {
  const { t } = useTranslation("front-page");
  const faqLists = [
    {
      question: "How does writingtools.ai differ from other AI Writing tools?",
      answer:
        "writingtools.ai is a comprehensive writing platform that offers a wide range of features to help users create high-quality content. It provides users with access to a variety of writing tools, including an AI writer, a grammar checker, a plagiarism checker, and a style editor. These tools are designed to help users create content that is both accurate and engaging. In addition, writingtools.ai offers users the ability to track their progress and receive feedback on their work. This makes it an ideal tool for anyone who wants to improve their writing skills.",
    },
    {
      question: "What types of content can I create with writingtools.ai?",
      answer:
        "You can create a wide range of content with writingtools.ai, including blog posts, articles, essays, and more.",
    },
    {
      question: "Can writingtools.ai write in different styles?",
      answer:
        "Yes, writingtools.ai allows you to choose from a variety of writing styles, including formal, informal, and creative.",
    },
    {
      question: "Can I use writingtools.ai for SEO?",
      answer:
        "Yes, writingtools.ai can help you optimize your content for SEO. It provides users with access to a variety of SEO tools, including keyword research, content analysis, and backlink analysis.",
    },
    {
      question:
        "Is  writingtools.ai safe to use? Can AI generated text be detected?",
      answer:
        "Yes, writingtools.ai is safe to use. We use the latest technology to ensure that your content is original and not plagiarized. We also use advanced algorithms to detect and prevent AI-generated text from being detected by search engines.",
    },
  ];

  return faqLists;
};
const Faq = React.memo(() => {
  const faqLists = useFaqList();
  return <FaqList faqLists={faqLists} />;
});

export default Faq;
