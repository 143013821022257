import { AutoPlayVideo, Video } from "components/video/auto-play-video";
import React from "react";
import { Container, ResponsiveText } from "./shared";
import { Box } from "components/ui/box";
import Image from "next/image";
import { Grid } from "components/ui/grid";
import { IconComponent } from "components/icon-component";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import { FeatureItem } from "modules/non-protected-page/feature-list";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import SEOMetadata from "public/images/generator/seo-metadata.png";
import { Button } from "components/ui/button";
import { NextLink } from "components/next-link";
import { serverRoutes } from "client-server-shared/config/routes";
import { Trans, useTranslation } from "components/translate";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import { HighlightedSpan, InlineSpan } from "components/as-inline-span";
import { Avatar } from "components/ui/avatar";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import DraftsIcon from "@mui/icons-material/Drafts";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { HighlightedCallToAction } from "../call-to-action";
import { isCn } from "client-server-shared/constants";

import AirlineStopsOutlinedIcon from "@mui/icons-material/AirlineStopsOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import HighlightOutlinedIcon from "@mui/icons-material/HighlightOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import { colors } from "src/constants";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import RssFeedOutlinedIcon from "@mui/icons-material/RssFeedOutlined";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import { WordPressIcon } from "client-server-shared/icons/word-press-icon";
import { MediumIcon } from "client-server-shared/icons/medium-icon";
import { WebflowIcon } from "client-server-shared/icons/webflow-icon";
const ContentRenderer = ({
  headline,
  subheadline,
  videoUrl,
  imageUrl,
  imageAlt,
  headlineProps = {},
  disableAutoPlay,
  rootProps = {},
}: any & { isSelected: boolean }) => {
  return (
    <Box
      component="article"
      sx={{
        height: "100%",
        borderRadius: "22px",
        ...rootProps,
      }}
    >
      <Box
        sx={{
          padding: {
            sm: "16px",
            xs: "16px",
            md: "24px",
            lg: "48px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "48px",
            rowGap: "24px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <ResponsiveText
              variant="h3"
              fontSize={30}
              decrement="medium"
              {...headlineProps}
              sx={{
                marginBottom: "12px",
                color: colors.black1,
                fontWeight: 600,
                ...(headlineProps.sx || {}),
              }}
            >
              {headline}
            </ResponsiveText>
            {subheadline && (
              <ResponsiveText
                fontSize={16}
                sx={{
                  color: colors.gray1,
                  marginBottom: "24px",
                  maxWidth: "500px",
                }}
              >
                {subheadline}
              </ResponsiveText>
            )}
          </Box>
          {videoUrl && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                height: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "100%",
                  xl: "100%",
                },
                flexShrink: 0,
                "& img": {
                  height: {
                    sm: "100%",
                    xs: "100%",
                    md: "initial",
                    lg: "initial",
                    xl: "initial",
                  },
                },
              }}
            >
              {disableAutoPlay ? (
                <Video
                  videoUrl={videoUrl}
                  controls
                  imageUrl={imageUrl}
                  style={{
                    borderRadius: "22px",
                  }}
                />
              ) : (
                <AutoPlayVideo
                  videoUrl={videoUrl}
                  controls
                  delay={500}
                  imageUrl={imageUrl}
                  style={{
                    borderRadius: "22px",
                  }}
                />
              )}
            </Box>
          )}
          {imageUrl && !videoUrl ? (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexBasis: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "50%",
                  xl: "50%",
                },
                height: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "100%",
                  xl: "100%",
                },
                flexShrink: 0,
                "& img": {
                  height: {
                    sm: "100%",
                    xs: "100%",
                    md: "initial",
                    lg: "initial",
                    xl: "initial",
                  },
                },
              }}
            >
              <Image
                src={imageUrl}
                alt={imageAlt}
                height={600}
                width={600}
                style={{
                  width: "100%",
                  borderRadius: "22px",
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const SmallCard = ({
  title,
  description,
  imageUrl,
  icon,
  iconProps = {},
  alt,
  iconComponent,
}) => {
  return (
    <Box
      component="article"
      sx={{
        backgroundColor: "#fcfbf50a",
        height: "100%",
        borderRadius: "10px",
        border: `1px solid #e5e7eb`,
      }}
    >
      <Box
        sx={{
          padding: "24px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "48px",
            rowGap: "24px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "10px",
                    padding: "6px",
                    backgroundColor: colors.red1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {iconComponent || (
                    <IconComponent
                      icon={icon}
                      {...iconProps}
                      sx={{
                        height: "24px",
                        width: "24px",
                        fill: colors.white1,
                      }}
                    />
                  )}
                </Box>
              </Box>

              <ResponsiveText
                variant="h6"
                fontSize={18}
                sx={{
                  marginBottom: "6px",
                  color: colors.black1,
                  fontWeight: 600,
                }}
              >
                {title}
              </ResponsiveText>
            </Box>
            {description && (
              <ResponsiveText
                fontSize={14}
                sx={{
                  color: colors.gray1,
                  maxWidth: "500px",
                }}
              >
                {description}
              </ResponsiveText>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

/*
        <Grid
            container
            spacing={3}
            sx={{
              margin: "12px 0 24px 0",
              ...heroSpacing,
            }}
          >
            <Grid sm={12} md={6} lg={4}>
              <SmallCard
                icon={MoreHorizOutlinedIcon}
                title={t("Continue writing")}
                description={t(
                  "Junia AI can seemlingly continue from where you left off, ensuring you one coherent article"
                )}
              />
            </Grid>
            <Grid sm={12} md={6} lg={4}>
              <SmallCard
                icon={EditOutlinedIcon}
                title={t("Paraphrase")}
                description={t(
                  "Rewrite your text in a fresh and engaging manner, without losing the essence of the original message."
                )}
              />
            </Grid>
            <Grid sm={12} md={6} lg={4}>
              <SmallCard
                icon={GTranslateOutlinedIcon}
                title={t("Rewrite to any language")}
                description={t(
                  "Repurpose your content for a global audience utilizing Junia's expertise in over 30+ languages."
                )}
              />
            </Grid>
          </Grid>
*/

export const Features = () => {
  const { t } = useTranslation("front-page");
  return (
    <>
      <Box
        component="section"
        sx={{
          padding: {
            xs: "70px 0 70px 0",
            sm: "70px 0 70px 0",
            md: "70px 0 70px 0",
            lg: "70px 0 70px 0",
          },
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
              margin: "0 0 70px 0",
            }}
          >
            <ResponsiveText
              variant="h2"
              fontSize={50}
              decrement="medium"
              sx={{
                maxWidth: "800px",
                color: colors.black1,
                textAlign: "center",
                margin: "auto",
                fontWeight: 700,
              }}
            >
              More than 100 AI writing tools to choose from
            </ResponsiveText>
            <ResponsiveText
              fontSize={18}
              sx={{
                color: colors.gray1,
                maxWidth: "500px",
                textAlign: "center",
                margin: "12px auto auto auto",
              }}
            >
              Choose the best AI writing tool for your needs, from SEO-optimized
              blog posts to social media content.
            </ResponsiveText>
          </Box>
          <Grid
            component="ul"
            container
            spacing={3}
            sx={{
              padding: 0,
              listStyle: "none",
              margin: "12px 0 24px 0",
            }}
          >
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={ContentPasteSearchOutlinedIcon}
                iconProps={{
                  sx: {
                    fill: "rgb(216 76 167)",
                  },
                }}
                title="AI Document Editor"
                description="Edit your documents with the power of AI."
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={HighlightOutlinedIcon}
                iconProps={{
                  sx: {
                    fill: "#f1e713",
                  },
                }}
                title="Long-Form AI Content Generator"
                description="Generate SEO-optimized long-form content."
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                iconProps={{
                  sx: {
                    fill: "#dc9ad9",
                  },
                }}
                icon={RocketLaunchOutlinedIcon}
                title="AI Content Rewriter"
                description="Rewrite your content in a fresh and engaging manner, without losing the essence of the original message."
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={NoteAltOutlinedIcon}
                iconProps={{
                  sx: {
                    fill: "#f1e713",
                  },
                }}
                title="AI Article Writer"
                description="Generate well-structured articles perfectly formatted with bullet points, quotes, and headings."
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={AirlineStopsOutlinedIcon}
                title="Email Responder"
                iconProps={{
                  sx: {
                    fill: "#00e983",
                  },
                }}
                description="Automatically respond to emails with AI."
              />
            </Grid>

            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={HistoryEduIcon}
                title="AI Speech Writer"
                iconProps={{
                  sx: {
                    fill: "#04d691",
                  },
                }}
                description="Generate AI-powered speeches for your next event."
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              capitalize
              variant="contained"
              component={NextLink}
              href={serverRoutes.tools}
              sx={{
                marginTop: "12px",
                borderRadius: "22px",
                padding: "12px 36px",
                background: colors.red1,
                fontSize: "20px",
                "&:hover": {
                  opacity: 0.8,
                  background: colors.red1,
                },
              }}
            >
              <ResponsiveText
                sx={{
                  color: colors.textWhite1,
                }}
                fontSize={20}
                decrement="medium"
              >
                Discover More AI Writing Tools
              </ResponsiveText>
            </Button>
          </Box>
        </Container>
      </Box>
      <Box
        component="section"
        sx={{
          padding: {
            xs: "70px 0 70px 0",
            sm: "70px 0 70px 0",
            md: "70px 0 70px 0",
            lg: "70px 0 70px 0",
          },
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
              margin: "0 0 70px 0",
            }}
          >
            <ResponsiveText
              variant="h2"
              fontSize={50}
              decrement="medium"
              sx={{
                maxWidth: "800px",
                color: colors.black1,
                textAlign: "center",
                margin: "auto",
                fontWeight: 700,
              }}
            >
              Create content in any format with AI
            </ResponsiveText>
            <ResponsiveText
              fontSize={18}
              sx={{
                color: colors.gray1,
                maxWidth: "500px",
                textAlign: "center",
                margin: "12px auto auto auto",
              }}
            >
              Our AI writing tools can create content in various formats, from
              short-form to long-form, from social media to SEO content, from
              blog posts to emails, from ads to social media posts, from videos
              to podcasts, and more.
            </ResponsiveText>
          </Box>
          <Grid
            component="ul"
            container
            spacing={3}
            sx={{
              padding: 0,
              listStyle: "none",
              margin: "12px 0 24px 0",
            }}
          >
            <Grid component="li" sm={12} md={6} lg={4}>
              <SmallCard
                icon={AutoFixHighOutlinedIcon}
                title="News Article Writer"
                description="Generate news articles with AI."
              />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={4}>
              <SmallCard
                icon={EditOutlinedIcon}
                title="Listical Article Writer"
                description="Generate listical articles with AI."
              />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={4}>
              <SmallCard
                icon={RocketLaunchOutlinedIcon}
                title="Pillar Content Writer"
                description="Generate pillar content with AI."
              />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={4}>
              <SmallCard
                icon={StickyNote2OutlinedIcon}
                title="Essay Writer"
                description="Generate Essay with AI."
              />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={4}>
              <SmallCard
                icon={RssFeedOutlinedIcon}
                title="Blog Post Writer"
                description="Generate Blog with AI."
              />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={4}>
              <SmallCard
                icon={GradeOutlinedIcon}
                title="Press Release Writer"
                description="GeneratePress Release with AI."
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        component="section"
        sx={{
          padding: {
            xs: "70px 0 70px 0",
            sm: "70px 0 70px 0",
            md: "70px 0 70px 0",
            lg: "70px 0 70px 0",
          },
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
              margin: "0 0 70px 0",
            }}
          >
            <ResponsiveText
              variant="h2"
              fontSize={50}
              decrement="medium"
              sx={{
                maxWidth: "800px",
                color: colors.black1,
                textAlign: "center",
                margin: "auto",
                fontWeight: 700,
              }}
            >
              Auto Publish To Your Website
            </ResponsiveText>
            <ResponsiveText
              fontSize={18}
              sx={{
                color: colors.gray1,
                maxWidth: "500px",
                textAlign: "center",
                margin: "12px auto auto auto",
              }}
            >
              Our AI writing tools integrate with various websites and platforms
              to automatically publish your content with schedule publishing
              options available.
            </ResponsiveText>
          </Box>
          <Grid
            component="ul"
            container
            spacing={3}
            sx={{
              padding: 0,
              listStyle: "none",
              margin: "12px 0 24px 0",
            }}
          >
            <Grid component="li" sm={12} md={6} lg={3}>
              <SmallCard icon={WordPressIcon} title="WordPress" />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={3}>
              <SmallCard
                iconComponent={
                  <Image
                    src="/icons/shopify_glyph.png"
                    height={24}
                    width={24}
                    style={{
                      height: "24px",
                      width: "24px",
                    }}
                  />
                }
                title="Shopify"
              />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={3}>
              <SmallCard icon={MediumIcon} title={"Medium"} />
            </Grid>
            <Grid component="li" sm={12} md={6} lg={3}>
              <SmallCard icon={WebflowIcon} title="Webflow" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
