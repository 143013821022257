import { serverRoutesConfig } from "client-server-shared/config/routes";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import {
  Container,
  ResponsiveText,
  ResponsiveTextProps,
} from "./front-page/shared";
import CheckIcon from "@mui/icons-material/Check";
import { CallToAction } from "./call-to-action";
import { useTranslation } from "components/translate";
import { colors } from "src/constants";

interface Props {
  title?: string;
  description?: string;
  checkList?: string[];
  callToAction?: string;
  callToActionProps?: any;
  to?: string;
  asBlank?: boolean;
  noBorder?: boolean;
  titleProps?: ResponsiveTextProps;
}

const ReadyToLevelUp = (props: Props) => {
  const { t } = useTranslation("common");
  const {
    title = "Change the way you write",
    description = "Tap into the power of AI to supercharge your writing and unlock your full potential.",
    callToAction = "Start Writing With AI",
    to = serverRoutesConfig.register,
    checkList = [
      "No credit card required",
      "Superior content generation",
      "100% risk-free",
    ],
    asBlank = false,
    callToActionProps = {},
    noBorder,
    titleProps = {},
    rel,
  } = props;
  return (
    <Box
      component="section"
      sx={{
        background: colors.pink1,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <ResponsiveText
          decrement="large"
          fontSize={50}
          fontWeight={600}
          variant="h6"
          marginBottom={24}
          textAlign={"center"}
          {...titleProps}
        >
          {title}
        </ResponsiveText>
        <ResponsiveText
          component="blockquote"
          fontSize={16}
          sx={{
            color: "#6F6D6D",
            maxWidth: "600px",
            margin: "auto",
            fontWeight: 600,
          }}
          textAlign={"center"}
        >
          {description}
        </ResponsiveText>
        {checkList.length > 0 ? (
          <Box
            alignCenter
            sx={{
              display: "flex",
              marginTop: "12px",
              justifyContent: "center",
              columnGap: "36px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
          >
            {checkList.map((text) => {
              return (
                <Box sameLine alignCenter key={text}>
                  <CheckIcon
                    sx={{
                      fill: "#435B8C",
                      marginRight: "5px",
                    }}
                  />
                  <Typography variant="body2" fontWeight={600}>
                    {text}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CallToAction
            rel={rel}
            variant="secondary"
            to={to}
            asBlank={asBlank}
            {...callToActionProps}
          >
            {callToAction}
          </CallToAction>
        </Box>
      </Container>
    </Box>
  );
};

export default ReadyToLevelUp;
