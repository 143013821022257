import React from "react";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import Balancer from "react-wrap-balancer";
import { headerHeight } from "modules/non-protected-page/shared";
import {
  Container,
  ResponsiveText,
  headlineSizeConfig,
} from "modules/marketing-pages/front-page/shared";
import ReadyToLevelUp from "modules/marketing-pages/ready-to-level-up";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "components/ui/grid";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { isCn, locale } from "client-server-shared/constants";
import { Trans, useTranslation } from "components/translate";
import { IndexPageHeadTags } from "common/head-tags";
import {
  HighlightedCallToAction,
  PrimaryCallToAction,
} from "modules/marketing-pages/call-to-action";
import Image from "next/image";
import DescriptionIcon from "@mui/icons-material/Description";

import { BlackButton, Button } from "components/ui/button";
import { Avatar } from "components/ui/avatar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { FeatureItem } from "modules/non-protected-page/feature-list";
import { NextLink } from "components/next-link";
import { templateServerRouteBySlug } from "client-server-shared/config/routes";
import Amazing from "modules/marketing-pages/front-page/amazing";

import { Features } from "modules/marketing-pages/front-page/features";
import Faq from "modules/marketing-pages/front-page/front-page-faq";

import { Pricing, PricingProvider } from "modules/payment/pricing";
import { SourceType } from "utils/tracking/type";
import { colors } from "src/constants";

const PurpleLinePointer1 = ({ sx = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      viewBox="0 0 1516 1887"
      fill="none"
      style={sx}
    >
      <path
        d="M1515.5 17.0045L0 17.0053"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <line
        x1="548.706"
        y1="1816.12"
        x2="548.706"
        y2="23.1172"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <circle cx="549" cy="1832" r="55" fill="#BF32D6" />
    </svg>
  );
};

const OverlapIcon = ({ icon, jsxIcon, noIconBg, sx = {}, reverse, index }) => {
  const Icon = icon;

  if (reverse) {
    return (
      <Box
        sx={{
          position: "relative",
          ...sx,
        }}
      >
        <Avatar
          sx={{
            width: "45px",
            height: "45px",

            background: "#CF24BE",
            ...(noIconBg ? { background: "transparent" } : {}),
          }}
        >
          <Box />
        </Avatar>
        <Avatar
          sx={{
            width: "45px",
            height: "45px",
            background: "transparent",
            bottom: "-15px",
            left: "-12px",
            position: "absolute",
            "&:after": {
              content: "''",
              background: "#F5F5F5",

              opacity: 0.4,
              position: "absolute",
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Icon
            sx={{
              zIndex: 2,
              width: "17px",
              height: "17px",
              fill: "white",
            }}
          />
        </Avatar>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Avatar
        sx={{
          width: "45px",
          height: "45px",
          position: "relative",
          background: "#CF24BE",
          ...(noIconBg ? { background: "transparent" } : {}),
        }}
      >
        {jsxIcon || index}
      </Avatar>
    </Box>
  );
};

const ThirdSectionSection = ({
  jsxIcon,
  icon,
  title,
  description,
  isLast,
  index,
  noIconBg,
  titleProps = {},
}) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        columnGap: "48px",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <OverlapIcon
          noIconBg={noIconBg}
          jsxIcon={jsxIcon}
          icon={icon}
          index={index}
        />
        <Box
          sx={{
            height: "50px",
            width: "1px",
            borderLeft: isLast ? 0 : "1px dashed #b59fd0",
            marginTop: "20px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          marginTop: "-6px",
        }}
      >
        <ResponsiveText
          color="text.white"
          fontSize={24}
          decrement="medium"
          {...titleProps}
          sx={{
            fontWeight: 700,
            ...(titleProps.sx || {}),
          }}
        >
          {title}
        </ResponsiveText>
        <ResponsiveText
          fontSize={18}
          decrement="medium"
          sx={{
            maxWidth: "400px",
          }}
          fontWeight={500}
          color="#BCBCBC"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const SithSectionSection = ({ text, icon }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "12px",
      }}
    >
      <Icon
        sx={{
          height: "60px",
          width: "60px",
          fill: "#FFC107",
        }}
      />
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 600,
        }}
        color={"#5DADE2"}
      >
        {text}
      </Typography>
    </Box>
  );
};

const SithSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: "0 0 170px 0",
      }}
    >
      <Container maxWidth="lg">
        <ResponsiveText
          variant="h4"
          fontSize={40}
          sx={{
            marginBottom: "72px",
          }}
          textAlign={"center"}
          color="text.white"
          fontWeight={600}
        >
          {t("Junia is used by")}
        </ResponsiveText>

        <Grid
          container
          component="ul"
          sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            justifyContent: "space-around",
            rowGap: "24px",
          }}
        >
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={AutoGraphOutlinedIcon}
              text={t("Marketers")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={LightbulbOutlinedIcon}
              text={t("Entrepreneurs")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={SchoolOutlinedIcon}
              text={t("Students")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={ContentPasteOutlinedIcon}
              text={t("Content creators")}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const Hero = React.memo(() => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        textAlign: "center",
        position: "relative",
        padding: "30px 0 0 0",
        minHeight: {
          xs: "initial",
          sm: "initial",
          md: "initial",
          xl: `calc(100vh - ${headerHeight}px)`,
        },
        padding: {
          xs: "30px 0 0 0",
          sm: "30px 0 0 0",
          md: "30px 0 0 0",
          lg: "70px 0 180px 0",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: {
            xs: `24px`,
            sm: `24px`,
            md: `calc((100vh - ${headerHeight}px) / 10)`,
            xl: `calc((100vh - ${headerHeight}px) / 10)`,
          },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={500}
          sx={{
            fontSize: headlineSizeConfig,
            "& > span": {
              fontSize: headlineSizeConfig,
            },
            color: colors.black1,
            textAlign: "center",
            //  maxWidth: "800px",
            lineHeight: 1.2,
            width: {
              xs: "100%",
              sm: `100%`,
              md: `100%`,
              xl: `100%`,
              lg: "100%",
            },
          }}
        >
          Best AI Writing Tool For Content Generation
        </Typography>
        <ResponsiveText
          color={colors.gray1}
          variant="body2"
          fontSize={22}
          textAlign={"center"}
          sx={{
            margin: "24px auto 0 auto",
            maxWidth: "800px",
            fontWeight: 400,
          }}
        >
          Use AI to generate high-quality content in minutes. From SEO-optimized
          blog posts, product descriptions to social media content, we've got an
          extensive range of AI writing templates to end your content creation
          struggles.
        </ResponsiveText>
        <HighlightedCallToAction
          rootProps={{
            sx: {
              margin: "48px auto 24px auto",
              maxWidth: "200px",
              padding: "12px 6px 12px 12px",
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            },
          }}
        >
          Try For Free
        </HighlightedCallToAction>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            columnGap: "8px",
          }}
        >
          <ResponsiveText
            fontSize={14}
            sx={{
              textAlign: "left",
              color: colors.black1,
            }}
          >
            No Credit Card Required
          </ResponsiveText>
        </Box>
      </Container>
    </Box>
  );
});

const MainHero = React.memo(() => {
  return <Hero />;
});

export default function IndexPage() {
  return (
    <>
      <IndexPageHeadTags />

      <Box
        sx={{
          background: colors.white1,
        }}
      >
        <MainHero />
      </Box>
      <Features />
      <Amazing />

      <Container
        maxWidth="xl"
        sx={{
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <PricingProvider>
          <Pricing page={SourceType.PricingPage} />
        </PricingProvider>
      </Container>
      <ReadyToLevelUp
        title="Change the way you write"
        titleProps={{
          sx: {
            maxWidth: "800px",
            margin: "auto auto 12px auto",
            lineHeight: 1.2,
          },
        }}
        callToAction="Start Writing With AI"
        description="Tap into the power of AI to supercharge your writing and unlock your full potential."
      />
      <Faq />
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["front-page", "common"])),
      // Will be passed to the page component as props
    },
  };
}
