import {
  ButtonWithLoading,
  ButtonWithLoadingProps,
} from "components/ui/button/button-with-loading";
import React from "react";
import { colors } from "src/constants";

export const PurpleButton = React.forwardRef(
  ({ children, ...rest }: ButtonWithLoadingProps, ref) => {
    return (
      <ButtonWithLoading
        capitalize
        ref={ref}
        {...rest}
        sx={{
          color: rest.disabled ? "#B3B3B3!important" : "#FFFFFF",
          background: rest.disabled ? "#D9D9D9" : colors.red1,
          "&:hover": {
            background: colors.red1,
            opacity: 0.8,
          },
          margin: "auto",
          padding: "6px 12px",
          ...(rest.sx || {}),
        }}
      >
        {children}
      </ButtonWithLoading>
    );
  }
);

export const PurpleOutlinedButton = React.forwardRef(
  ({ children, ...rest }: ButtonWithLoadingProps, ref) => {
    return (
      <ButtonWithLoading
        variant="contained"
        ref={ref}
        {...rest}
        capitalize
        sx={{
          display: "flex",
          padding: "6px 24px",
          background: "#D831D1",
          "&:hover": {
            background: "#D831D1",
            opacity: 0.8,
          },
          ...(rest.sx || {}),
        }}
      >
        {children}
      </ButtonWithLoading>
    );
  }
);

export const PurplePinkButton = React.forwardRef(
  ({ children, ...rest }: ButtonWithLoadingProps, ref) => {
    return (
      <ButtonWithLoading
        variant="contained"
        ref={ref}
        {...rest}
        capitalize
        sx={{
          display: "flex",
          padding: "6px 24px",
          color: rest.disabled ? "#B3B3B3!important" : colors.textWhite1,
          background: rest.disabled ? "#D9D9D9" : colors.red1,
          "&:hover": {
            background: colors.red1,
            opacity: 0.8,
          },
          ...(rest.sx || {}),
        }}
      >
        {children}
      </ButtonWithLoading>
    );
  }
);
