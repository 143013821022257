import { Box } from "components/ui/box";
import { Container } from "components/ui/container";

import React from "react";
import { ResponsiveText } from "./shared";

import { useTranslation } from "components/translate";
import { useMainFeatureList } from "./use-data";
import { AutoPlayVideo } from "components/video/auto-play-video";
import { Grid } from "components/ui/grid";
import { HighlightedSpan } from "components/as-inline-span";
import { HighlightedCallToAction } from "../call-to-action";

const ContentRenderer = ({
  headline,

  subheadline,
  content,
  withVideo,
}: any & { isSelected: boolean }) => {
  return (
    <Box
      component="article"
      sx={{
        backgroundColor: "#111827",
        height: "100%",
        borderRadius: "22px",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      }}
    >
      <Box
        sx={{
          padding: "48px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "48px",
            rowGap: "24px",
            width: "100%",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: withVideo ? "50%" : "100%",
              position: "relative",
            }}
          >
            <ResponsiveText
              variant="h3"
              fontSize={30}
              sx={{
                marginBottom: "24px",
                color: "text.white",
                fontWeight: 600,
              }}
            >
              {headline}
            </ResponsiveText>
            {subheadline && (
              <ResponsiveText
                fontSize={20}
                sx={{
                  color: "#D4DCE5",
                  marginBottom: "24px",
                }}
              >
                {subheadline}
              </ResponsiveText>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "24px",
                //  maxWidth: "495px",
              }}
            >
              {content.map((block, index) => {
                const Icon = block.icon;
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "24px",
                    }}
                  >
                    {Icon && (
                      <Box
                        sx={{
                          borderRadius: "10px",
                          padding: "6px",
                          backgroundColor: "rgba(41, 52, 76, 1)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          sx={{
                            minWidth: "24px",
                            minHeight: "24px",
                            fill: "#D4DCE5",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      {block.title && (
                        <ResponsiveText
                          sx={{
                            color: "text.white",
                            fontWeight: 800,
                          }}
                          fontSize={20}
                        >
                          {block.title}
                        </ResponsiveText>
                      )}
                      <ResponsiveText
                        variant="body1"
                        fontSize={16}
                        className="with-html-black-bg"
                        fontWeight={600}
                        dangerouslySetInnerHTML={{ __html: block.description }}
                        sx={{
                          color: "#D4DCE5",
                        }}
                      ></ResponsiveText>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          {withVideo && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexBasis: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "50%",
                  xl: "50%",
                },
                height: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "100%",
                  xl: "100%",
                },
                flexShrink: 0,
                "& img": {
                  height: {
                    sm: "100%",
                    xs: "100%",
                    md: "initial",
                    lg: "initial",
                    xl: "initial",
                  },
                },
              }}
            >
              <AutoPlayVideo
                videoUrl="/videos/generator/quick-generator.mp4"
                controls
                delay={500}
                imageUrl="/videos/generator/quick-generator.png"
                style={{
                  width: "100%",
                  borderRadius: "22px",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const Amazing = () => {
  const [selectedTab, setSelectedTab] = React.useState("emails");

  const onTabChange = (tab: string) => {
    setSelectedTab(tab);
  };
  const contentList = useMainFeatureList();
  const { t } = useTranslation("front-page");
  return null;
  return (
    <Box
      component="section"
      sx={{
        padding: "70px 0 0 0",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            marginBottom: "48px",
          }}
        >
          <ContentRenderer
            {...contentList[0]}
            subheadline={
              <HighlightedSpan
                inherit
                sx={{
                  color: "#E910D3",
                }}
              >
                {contentList[0].subheadline}
              </HighlightedSpan>
            }
            withVideo
          />
        </Box>
        <HighlightedCallToAction
          rootProps={{
            sx: { maxWidth: "350px", margin: "12px auto 0 0" },
          }}
        >
          {t("Get Authentic Long-Form Blog Posts")}
        </HighlightedCallToAction>
      </Container>
    </Box>
  );
};

export default Amazing;
